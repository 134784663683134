<template>
  <div>
    <UserFeedback :target="t('componentNames.mentorListCourse')" class="end mr-4 mt-1" :position="'is-left'"></UserFeedback>
    <b-field grouped group-multiline class="pb-1">
      <b-field>
       <b-button
        :loading="isLoading"
        @click="getMyCourses"
        icon-left="refresh"
        >{{t('common.refresh')}}</b-button
      >
      </b-field>
        <b-field :label="t('common.from')" label-position="on-border">
          <b-datepicker
            v-model="date.from"
            icon="calendar-today"
          ></b-datepicker>
        </b-field>
        <b-field :label="t('common.to')" label-position="on-border">
          <b-datepicker
            v-model="date.to"
            icon="calendar-today"
            :min-date="dateFrom"            
            overflow: visible
          ></b-datepicker>
        </b-field>   
    </b-field>
    <b-table
      narrowed
        :loading="isLoading"
      :data="courses"
      striped
      hoverable      
      detailed
      detail-key="id"
    >
      <b-table-column
        field="name"
        searchable
        sortable
        :label="t('forms.name')"
        v-slot="props"
        >{{ props.row.name }}</b-table-column
      >
      <b-table-column
        field="level"
        searchable
        sortable
        :label="t('common.level')"
        v-slot="props"
        >{{ mGetLevelCourseName(props.row.level) }}</b-table-column
      >
      <b-table-column
        field="starts"
        searchable
        sortable
        :label="t('course.start')"
        v-slot="props"
        >{{ mDate(props.row.starts) }}</b-table-column
      >
      <b-table-column
        field="ends"
        searchable
        sortable
        :label="t('course.end')"
        v-slot="props"
        >{{ mDate(props.row.ends) }}</b-table-column
      >
      <b-table-column
        field="tags"
        searchable
        sortable
        :label="t('forms.tags')"
        v-slot="props"
        ><b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" size="is-small">
            {{ tag }}
          </b-tag>
        </b-taglist></b-table-column
      >
      <b-table-column field="pdfFile" searchable sortable :label="t('course.syllabus')" v-slot="props">
        <b-button
          icon-left="book-open"
          size="is-small"
          @click="downloadFile(props.row.pdfFile)"
          v-if="props.row.pdfFile"
        >{{t('course.downloadSyllabus')}}</b-button>
      </b-table-column>
    <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
      <template slot="detail" slot-scope="props">
        <CourseDetail
          :idCourse="props.row.id"
        ></CourseDetail>
      </template>
    </b-table>
  </div>
</template>

<script>
import { Action } from "@/store/config_actions";
import Commons from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import CourseDetail from "../CourseDetail";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "CourseList",
  mixins: [Commons, TypesHelpers],
  components: {
    CourseDetail,
    UserFeedback
  },

  props: {
    refreshTrigger: { type: Number, required: true },
  },

  data: function () {
    return {
      courses: [],
      isLoading: false,
      date: {
        from: null,
        to: null,
      },
    };
  },

  watch: {
    refreshTrigger() {
      this.getMyCourses();
    },

    dateFrom() {
      if (this.date.to && this.date.from) {
        this.getMyCourses();
      }
    },

    dateTo() {
      if (this.date.to && this.date.from) {
        this.getMyCourses();
      }
    },
  },

  computed: {
    dateFrom() {
      if(this.date.from){
      return new Date(this.date.from);
      }else{
        return new Date()
      }
    },

    dateTo() {
      return new Date(this.date.to);
    },
  },

  mounted() {
    this.date.to = new Date(this.mNowPlusDays(30));
    this.date.from = new Date(this.mNowPlusDays(-10));
    //this.getMyCourses();
  },

  methods: {
    downloadFile(props) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_GET_IMAGE, props)
        .then((response) => {
          this.isLoading = false;
          var blob = new Blob([response], { type: 'application/pdf' })
          let url = URL.createObjectURL(blob)
          window.open(url, "_blank", "location=yes,scrollbars=yes")
          URL.revokeObjectURL(url)
        })
        .catch((error) => {
          this.isLoading = false;
          this.mDangerSnack(error.toString());
        });
    },

    /**
     * Download courses
     */
    getMyCourses() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_GET_TEACHER_COURSES, this.date)
        .then((payload) => {
          this.courses = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  overflow: visible !important;
}
div.end{
  float: right;
}
</style>
