<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
      <b-tab-item :label="t('common.courses')" icon="briefcase">
        <CourseMentorList :refreshTrigger="refreshTrigger"></CourseMentorList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.courseStudents')" icon="account-multiple">
        <CourseListStudents :refreshTrigger="refreshTrigger"></CourseListStudents>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CommonHelpers from '@/mixins/commons'
import CourseMentorList from './CourseMentorList'
import CourseListStudents from './CourseListStudents'

export default {
  name: 'CoursesManagement',
  mixins: [CommonHelpers],
  components: {
    CourseMentorList,
    CourseListStudents
  },

  props: {},

  data: function () {
    return {
      refreshTrigger: 0
    }
  },

  mounted() {
  },

  watch: {
  },

  computed: {
  },

  methods: {
  }
}
</script>

<style scoped>
.card {
  overflow: visible !important;
}

.tabs li.is-active a span {
  color: red !important;
  font-weight: bolder;
  font-size: medium !important;
}
</style>
