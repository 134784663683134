<template>
  <b-table :data="classrooms" striped>
    <b-table-column field="type" sortable :label="t('common.type')" v-slot="props">
      <b-tooltip
        :label="mGetClassroomTypeName(props.row.type)"
        position="is-right"
      >
        <b-icon :icon="mGetClassroomTypeIcon(props.row.type)"></b-icon>
      </b-tooltip>
    </b-table-column>
    <b-table-column
      field="name"
      searchable
      sortable
      :label="t('classroom.name')"
      v-slot="props"
    >
      {{ props.row.name }}
    </b-table-column>
    <b-table-column
      field="starts"
      searchable
      sortable
      :label="t('classroom.start')"
      v-slot="props"
    >
      {{ mDateTime(props.row.starts) }}
    </b-table-column>
    <b-table-column
      field="ends"
      searchable
      sortable
      :label="t('classroom.stop')"
      v-slot="props"
    >
      {{ mDateTime(props.row.ends) }}
    </b-table-column>
    <b-table-column v-if="isAdmin || isTeacher"
      field="lbusers"
      sortable
      :label="t('classroom.numberStudents')"
      v-slot="props"
    >
      {{ props.row.users.length }}
    </b-table-column>
    <b-table-column v-if="isAdmin || isTeacher"
      field="tags"
      searchable
      sortable
      :label="t('forms.tags')"
      v-slot="props"
    >
      <b-taglist>
        <b-tag v-for="tag in props.row.tags" :key="tag" size="is-small">
          {{ tag }}
        </b-tag>
      </b-taglist>
    </b-table-column>
    <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
  </b-table>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";
import TypesHelpers from "@/mixins/types";

export default {
  name: "CourseDetail",
  mixins: [CommonHelpers, TypesHelpers],
  components: {},

  props: {
    idCourse: { type: String, required: true },
  },

  data: function () {
    return {
      classrooms: [],
    };
  },

  mounted() {
    this.getClassrooms();
  },

  watch: {},

  computed: {

    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },
  },

  methods: {
    getClassrooms() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.CLASSROOM_GET_MYCLASSROOM_FOR_CLASSID, this.idCourse)
        .then((payload) => {
          this.classrooms = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
